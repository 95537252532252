@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
  --font-family: "Roboto", sans-serif;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  src: url("../public/fonts/Inter-Regular.woff2") format("woff2");
  font-display: swap;
  font-weight: 400 500 600;
}

* {
  font-family: Inter;
}
:focus,
:focus-visible {
  outline: none !important;
}

/* For Webkit browsers (Chrome, Safari) */
.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

/* For Firefox */
.scrollbar-hide {
  scrollbar-width: none;
}

/* For IE and Edge */
.scrollbar-hide {
  -ms-overflow-style: none;
}

.tabSelected.first::before,
.tabSelected.first > div::before,
.tabSelected.last::after,
.tabSelected.last > div::after {
  display: none;
}

.tabSelected::after {
  content: "";
  width: 12px;
  height: 12px;
  background: #f7f8f1;
  z-index: 1;
  position: absolute;
  bottom: 0;
  right: -12px;
}

.tabSelected::before {
  content: "";
  width: 12px;
  height: 12px;
  background: #f7f8f1;
  z-index: 1;
  position: absolute;
  bottom: 0;
  left: -12px;
}

.tabSelected > div::after {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  z-index: 9;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  right: -20px;
}

.tabSelected > div::before {
  content: "";
  width: 20px;
  height: 20px;
  background: #fff;
  z-index: 9;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  left: -20px;
}

.has-after::after {
  content: " ";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: #d14826;
  position: absolute;
  top: 45%;
  right: -10px;
}

/* Add this CSS to your stylesheets */
.slide-down {
  animation: slideDown 0.3s ease-out forwards;
}

@keyframes slideDown {
  from {
    max-height: 0;
    opacity: 0;
    overflow: hidden;
  }

  to {
    max-height: 500px;
    /* Adjust as necessary */
    opacity: 1;
    overflow: visible;
  }
}

.animationEffect::after {
  content: "";
  top: 0;
  left: -10%;
  width: 10px;
  height: 100%;
  position: absolute;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.6) 79%,
    rgba(255, 255, 255, 0.6) 80%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: tagAnimation 1.5s infinite ease-out;
}

@-webkit-keyframes tagAnimation {
  from {
    left: -10%;
  }

  to {
    left: 110%;
  }
}

@-moz-keyframes tagAnimation {
  from {
    left: -10%;
  }

  to {
    left: 110%;
  }
}

@keyframes tagAnimation {
  from {
    left: -10%;
  }

  to {
    left: 110%;
  }
}

.animate-growAndFade {
  animation: growAndFade 1s infinite ease-in-out;
}

@keyframes growAndFade {
  0% {
    width: 100px;
    height: 100px;
    border-color: rgba(224, 224, 224, 0);
    /* transparent border */
  }

  45% {
    width: 100%;
    height: 100%;
    border-color: rgba(224, 224, 224, 1);
    /* visible border */
  }

  50% {
    width: 100%;
    height: 100%;
    border-color: rgba(224, 224, 224, 1);
    /* visible border */
  }

  100% {
    width: 100px;
    height: 100px;
    border-color: rgba(224, 224, 224, 0);
    /* visible border */
  }
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
}

.custom-pagination {
  @apply top-full;
}

.splide__pagination__page.is-active {
  @apply bg-gray-500 !important;
}
@layer base {
  a {
    @apply no-underline; /* Remove default underline */
    -webkit-tap-highlight-color: rgba(0, 0, 255, 0.3); /* Default tap highlight color */
    color: inherit; /* Inherit text color */
  }
}
@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }

  .custom-scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #d4d4d4 transparent;
  }

  .custom-scrollbar:hover {
    scrollbar-color: #b5b5b5 transparent;
  }
}

.descriptionTitle {
  line-height: 1.4;
  font-size: 13px;
  color: #222;
}

.descriptionTitle h4 {
  color: #222;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: 1px solid #d5d5d5;
  font-weight: 500;
  font-size: 20px;
}

.descriptionTitle p {
  margin: 2px 0 5px 0;
  line-height: 18px;
  font-size: 13px;
  color: #222;
  text-align: justify;
}

.descriptionTitle b {
  font-weight: 500;
}

.descriptionTitle ul {
  margin: 0 0 10px 15px;
  list-style: none;
}

.descriptionTitle ul li {
  position: relative;
  margin-bottom: 6px;
}

.descriptionTitle ul li::before {
  position: absolute;
  width: 5px;
  height: 5px;
  background: #ccc;
  border-radius: 50%;
  content: "";
  left: -13px;
  top: 6px;
}

#longDescription h2,
#shortDescription h2 {
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  color: #222;
}
#longDescription h3,
#longDescription h4,
#longDescription h5,
#longDescription h6,
#shortDescription h3,
#shortDescription h4,
#shortDescription h5,
#shortDescription h6 {
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;
  color: #222;
}
#longDescription p,
#shortDescription p {
  margin-top: 0;
  font-size: 14px;
  line-height: 2;
  margin-bottom: 15px;
  color: #222;
}
#longDescription a,
#shortDescription a {
  color: #195ee0;
}
#longDescription ul,
#shortDescription ul {
  list-style: disc !important;
  padding-left: 20px;
  font-size: 14px;
  line-height: 2;
}
#longDescription .blogs{
  display: flex;
    gap: 40px;
    flex-wrap: wrap;
}
#longDescription .blogs li{
  width: 30%;
}
#longDescription table{
  border-collapse: collapse;
  margin-bottom: 20px;
}
#longDescription table td, #longDescription table th {
  border: 1px solid #ddd;
  padding: 8px;
}

#longDescription table tr:nth-child(even){background-color: #f2f2f2;}

#longDescription table tr:hover {background-color: #ddd;}

#longDescription table th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
.splide__arrow {
  opacity: 0.7 !important;
  transition: all ease-in-out 0.25s;
}
.splide__arrow:hover {
  opacity: 1 !important;
}
.custom-arrow {
  width: 40px !important;
  height: 40px !important;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #fff !important;
  opacity: 0.8;
  transition: all ease-in-out 0.25s;
}
.custom-arrow:hover {
  opacity: 1;
  border: 1px solid #e0e0e0;
}
.custom-arrow::after,
.custom-arrow::before {
  display: none;
}
/* .customDots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customDots li {
  width: auto !important;
  display: flex !important;
  justify-content: center;
  align-items: center;
}
.customDots li.slick-active .customDot, .customDots li .customDot {
  border-radius: 20px;
  background: #444;
  color: #fff;
  padding: 4px 8px;
  font-size: 10px;
} */
/* Default dot */
.customDots {
  display: flex;
  justify-content: center;
  align-items: center;
}
.customDots li {
  margin: 0 4px !important;
}

.customDots .customDot {
  width: 4px;
  height: 4px;
  background-color: #999;
  border-radius: 50%;
  /* transition: all 0.3s ease; */
}

/* Active dot */
.customDots .active .customDot {
  width: auto;
  height: auto;
  padding: 4px 8px;
  background-color: #333;
  color: white;
  font-size: 12px;
  font-weight: bold;
  border-radius: 16px;
}

/* Larger dot (first after/before active) */
.customDots .next .customDot {
  width: 12px;
  height: 12px;
  background-color: #666;
}

/* Smaller dot (second after/before active) */
.customDots .next-smaller .customDot {
  width: 10px;
  height: 10px;
  background-color: #888;
}

/* Smallest dot (third after/before active) */
.customDots .next-smallest .customDot {
  width: 8px;
  height: 8px;
  background-color: #aaa;
}

.slick-arrow.slick-disabled {
  display: none !important;
}
.slick-dots {
  bottom: 10px !important;
  width: auto !important;
  left: 0 !important;
  right: 0 !important;
}
.slick-dots li {
  width: auto !important;
  height: auto !important;
  margin: 0 3px !important;
}
.slick-dots li button {
  width: 8px !important;
  height: 8px !important;
  padding: 0 !important;
  background: #e0e0e0 !important;
  border-radius: 50%;
}
.slick-dots.addPlayDots li:nth-child(2) button {
  box-sizing: border-box;
  border: 4px solid #fff;
  border-width: 4px 0px 4px 8px;
  background: rgba(0, 0, 0, 0) !important;
  border-radius: 0 !important;
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #e0e0e0;
}
.slick-dots li.slick-active button {
  background: #444 !important;
}
.slick-dots.addPlayDots.playDotsActive li.slick-active:nth-child(2) button {
  border-left-color: #444 !important;
  background: rgba(0, 0, 0, 0) !important;
}
.slick-dots li button::before {
  display: none !important;
}
.customerReviews .slick-track {
  display: flex !important;
  height: 100%;
  align-items: stretch;
}
.customerReviews .slick-slide {
  padding-right: 16px !important;
  float: none !important;
}
.customerReviews.slick-initialized .slick-slide {
  display: flex !important;
  flex-wrap: wrap;
  height: auto !important;
}
.customerReviews.slick-initialized .slick-slide > div {
  width: 100% !important;
}
.pdpSlider .slick-arrow.slick-next.custom-arrow {
  left: 50%;
  transform: translateX(-50%) rotate(90deg) !important;
  top: auto !important;
  bottom: 70px !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
}
.pdpSlider .slick-arrow.slick-prev.custom-arrow {
  transform: translateX(-50%) rotate(90deg) !important;
  top: 0 !important;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  left: 50% !important;
}
#microSiteContent .categorydescp {
  padding: 25px;
  text-align: justify;
}
#microSiteContent .categorydescp p {
  font-size: 14px;
  color: #555;
  margin-bottom: 20px;
  line-height: 22px;
}
#microSiteContent .categorydescp h2,
#microSiteContent .categorydescp h3 {
  margin-bottom: 10px;
  color: #222;
  font-weight: 500;
  font-size: 18px;
}

#heroBanner .slick-slide,
.categorySlider .slick-slide,
.logoSlider .slick-slide {
  padding: 0 16px;
}
#heroBanner .slick-list,
.categorySlider .slick-list,
.logoSlider .slick-list {
  margin: 0 -16px;
}
.slick-track {
  margin-left: 0 !important;
}
.pdpSlider .custom-arrow {
  width: 40px !important;
  height: 40px !important;
}
.productCarousel .slick-list {
  margin: 0 -12px;
}
.productCarousel .slick-slider .slick-track::before {
  content: none !important;
  display: none !important;
}

.category_new_UX img {
  transition: all ease-in-out 0.25s;
}
.category_new_UX:hover img {
  transform: scale(1.03);
}
#offerTnc ul {
  list-style: disc !important;
  margin-left: 10px;
}
#offerTnc ul li {
  margin-bottom: 16px;
}
.collapse {
  visibility: visible !important;
}
@media screen and (max-width: 1366px) {
  .custom-arrow {
    width: 40px !important;
    height: 40px !important;
  }
  .pdpSlider .slick-arrow.slick-next.custom-arrow {
    bottom: 45px !important;
  }
}

@media (min-width: 768px) and (max-width: 1920px) {
  .pdpSlider .slick-track {
    height: auto !important;
  }
}
@media screen and (max-width: 1024px) {
  #splide02-track {
    overflow-x: scroll;
    scrollbar-width: none;
  }
  #longDescription h2 {
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 10px;
    color: #222;
  }
  #longDescription h3,
  #longDescription h4,
  #longDescription h5,
  #longDescription h6 {
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 10px;
    color: #222;
  }
  #longDescription .blogs{
    gap: 20px;
  }
  #longDescription .blogs li{
    width: 100%;
  }
}

.modalGallery,
.iiz,
.iiz > div,
.iiz__img {
  height: 100%;
}

.iiz__hint {
  display: none !important;
}
.iiz img {
  border-radius: 8px;
}
#productGallery .slick-dots {
  bottom: -22px !important;
}
#plpProduct .dynamicHeight {
  display: block !important ;
  height: 1rem !important;
}

.leftAlignSlick .slick-track {
  margin-left: unset !important;
}

.countryDropdown {
  img {
    max-width: 100%;
    width: revert-layer;
  }
}
